import type { MaybeRef, WritableComputedRef } from 'vue'
import type {
  IOptionExerciseDate,
  IOptionExerciseDetailsValue,
  IOptionsConfiguration,
  IOptionsForm,
} from '../types'
import type { IOptionExerciseDetailsValue as IOptionExerciseDetailsTableValue } from '@register/components/Review/Form/OptionExerciseDetails'
import { useOptionsGenerator, useTableData } from '@manager'

export function useOptionExerciseDetailsData(
  initialValue: MaybeRef<IOptionExerciseDetailsValue[]>,
  formValue: WritableComputedRef<IOptionsForm>,
) {
  const dayjs = useDayjs()
  const expiryDate = computed(
    () => formValue.value.supportFieldsGroup?.data?.[0]?.expiryDate,
  )
  const exerciseStartDate = computed(() => formValue.value.exerciseStartDate)
  const exerciseEndDate = computed(() => formValue.value.exerciseEndDate)

  const {
    data,
    filteredData,
    add,
    addOrUpdate,
    update,
    updateById,
    remove,
    getById,
  } = useTableData(initialValue, {
    initialItem: () => {
      return {
        isExercised: false,
        includeInForecast: true,
        createNewLease: null,
      }
    },
  })

  const generate = useOptionsGenerator({
    onAddOption: (index, _, prevConfig) => {
      const prevItem = data.value[index - 1]
      const item = data.value[index]
      const status = [REPEATER_ITEM_STATUS.CREATED, REPEATER_ITEM_STATUS.EDITED]
      if (item && status.includes(item.__status)) return

      addOrUpdate(index, {
        option: index + 1,
        exerciseStartDate: exerciseStartDate.value
          ? generateExerciseDate(
              prevItem?.exerciseStartDate || expiryDate.value!,
              exerciseStartDate.value,
              prevConfig,
            )
          : undefined,
        exerciseEndDate: exerciseEndDate.value
          ? generateExerciseDate(
              prevItem?.exerciseEndDate || expiryDate.value!,
              exerciseEndDate.value,
              prevConfig,
            )
          : undefined,
        __status: REPEATER_ITEM_STATUS.GENERATED,
      })
    },
    onRemoveOption: (index) => {
      const item = data.value[index]
      if (item && !item.isExercised) remove(item, index)
    },
    maxIterations: 200,
  })

  debouncedWatch(
    [
      () => formValue.value.optionsConfiguration?.data ?? [],
      () => formValue.value.exerciseStartDate,
      () => formValue.value.exerciseEndDate,
    ],
    ([newConfigurations]) => {
      if (!expiryDate.value) return

      generate(
        newConfigurations.filter(
          (config) => config.__status !== REPEATER_ITEM_STATUS.DELETED,
        ),
      )
    },
    // debounce must be lower than evaluate debounce (300)
    { immediate: true, deep: true, debounce: 150 },
  )

  return {
    items: data,
    filteredItems: computed(() =>
      filteredData.value.map<IOptionExerciseDetailsTableValue>((item) => ({
        __id: item.__id,
        option: item.option ?? undefined,
        exerciseStartDate: item.exerciseStartDate ?? undefined,
        exerciseEndDate: item.exerciseEndDate ?? undefined,
        isExercised: item.isExercised ?? undefined,
        includeInForecast: item.includeInForecast ?? undefined,
      })),
    ),
    add,
    update,
    updateById,
    getById,
  }

  function generateExerciseDate(
    date: string,
    exerciseDate: IOptionExerciseDate,
    config: IOptionsConfiguration | undefined,
  ) {
    if (!config) {
      return dayjs(date)
        .subtract(Number(exerciseDate?.months || 0), 'months')
        .subtract(Number(exerciseDate?.days || 0), 'days')
        .toISOString()
    }

    return dayjs(date)
      .add(Number(config.years || 0), 'year')
      .add(Number(config.months || 0), 'month')
      .add(Number(config.days || 0), 'day')
      .toISOString()
  }
}
