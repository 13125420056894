<script setup lang="ts">
import type { WritableComputedRef } from 'vue'
import { useModal } from 'vue-final-modal'
import type {
  IManagerTableEmits,
  IManagerTableProps,
} from '@manager/components/Group/Table/types'
import {
  type INodeObject,
  ManagerFormSubmit,
  parseSizeToGrid,
  type RepeaterItem,
} from '@manager'
import { Table as OptionExerciseDetailsTable } from '@register/components/Review/Form/OptionExerciseDetails'
import type { IOptionExerciseDetailsValue, IOptionsForm } from './types'
import { useOptionExerciseDetailsData } from '@manager/components/Group/Custom/OptionExerciseDetailsTable/composables/useOptionExerciseDetailsData'
import { useQueryClient } from '@tanstack/vue-query'

const props = defineProps<IManagerTableProps>()
const emit = defineEmits<IManagerTableEmits>()

const colSize = computed(() => parseSizeToGrid(props.node.size))

const objectNode = props.node.nodes[0] as INodeObject
const [parentValue, dataModelValue] = useObjectVModel(
  {
    ...props,
    node: objectNode,
  },
  'parentValue',
  emit,
)

const router = useRouter()
const divisionId = getCurrentDivisionId()
const { dateFormat: leaseDateFormat } = useCurrentLease()
const queryClient = useQueryClient()

const submitForm = inject(ManagerFormSubmit)!

const { formValue } = useManagerFormValue<IOptionsForm>()
const leaseEndDate = computed(
  () => formValue.value.supportFieldsGroup?.data?.[0]?.expiryDate,
)

const { filteredItems, update, updateById, getById } =
  useOptionExerciseDetailsData(
    dataModelValue as WritableComputedRef<IOptionExerciseDetailsValue[]>,
    formValue,
  )

const itemValue = ref<RepeaterItem<IOptionExerciseDetailsValue>>()
const closeFormModal = () => {
  _closeFormModal().then(() => {
    itemValue.value = undefined
    patchOptions({
      attrs: {
        // @ts-expect-error - Reset modal prop
        optionExerciseDetail: undefined,
      },
    })
  })
}
const {
  open: openFormModal,
  close: _closeFormModal,
  patchOptions,
} = useModal({
  component: getAsyncComponent('ReviewFormOptionExerciseDetailsForm'),
  // @ts-expect-error - `optionExerciseDetail` is set via `patchOptions`
  attrs: {
    isManager: true,
    onConfirm: (item) => {
      try {
        // Updating
        if (itemValue.value) {
          update(itemValue.value, {
            exerciseStartDate: item.exerciseStartDate,
            exerciseEndDate: item.exerciseEndDate,
          })
          closeFormModal()
        }
      } catch (e) {
        console.error(e)
      }
    },
    onClose: () => {
      closeFormModal()
    },
  },
})

const onEdit = (id: string) => {
  const item = getById(id)
  if (item) {
    itemValue.value = item

    patchOptions({
      attrs: {
        optionExerciseDetail: {
          exerciseStartDate: item.exerciseStartDate ?? undefined,
          exerciseEndDate: item.exerciseEndDate ?? undefined,
        },
      },
    })
    openFormModal()
  }
}

const resetExerciseCreateLeaseModal = () => {
  exerciseCreateLeaseModal.patchOptions({
    // @ts-expect-error - Reset modal prop
    attrs: {
      commencementDate: undefined,
      expiryDate: undefined,
      baseRent: undefined,
    },
  })
}
const exerciseModal = useModal({
  component: getAsyncComponent(
    'ManagerGroupCustomOptionExerciseDetailsTableExerciseFlow',
  ),
  attrs: {
    onCreateLease: () => {
      if (!itemValue.value) return
      exerciseModal.close()
      exerciseCreateLeaseModal.patchOptions({
        attrs: {
          commencementDate: itemValue.value.newLeaseCommencementDate,
          expiryDate: itemValue.value.newLeaseExpiryDate,
          baseRent: itemValue.value.newLeaseBaseRent,
        },
      })
      exerciseCreateLeaseModal.open()
    },
    onCreateVariation: () => {
      if (!itemValue.value) return
      exerciseModal.close()
      exerciseCreateVariationModal.open()
    },
    onClose: () => {
      exerciseModal.close().then(() => {
        itemValue.value = undefined
      })
    },
  },
})
const exerciseCreateLeaseModal = useModal({
  component: getAsyncComponent(
    'ManagerGroupCustomOptionExerciseDetailsTableExerciseFlowCreateLease',
  ),
  attrs: {
    // @ts-expect-error - Reactivity
    leaseDateFormat,
    utc: true,
    onConfirm: async ({ baseRent }) => {
      try {
        // Updating
        if (itemValue.value) {
          update(itemValue.value, {
            isExercised: !itemValue.value.isExercised,
            newLeaseBaseRent: baseRent,
            createNewLease: true,
          })
          const leaseId = await submitForm()
          // We already invalidate the cache in the `submitForm` function,
          // but we await here so when we redirect to the new lease page
          // the "confirm without saving" modal won't show up
          await queryClient.invalidateQueries({
            queryKey: [
              'companies',
              divisionId,
              'leases',
              leaseId,
              'storage',
              'node',
            ],
          })
          exerciseCreateLeaseModal.close().then(() => {
            itemValue.value = undefined
            resetExerciseCreateLeaseModal()
          })
          router.push(`/division/${divisionId}/lease/${leaseId}/manager`)
        }
      } catch (e) {
        console.error(e)
      }
    },
    onClose: () => {
      exerciseCreateLeaseModal.close().then(() => {
        resetExerciseCreateLeaseModal()
        exerciseModal.open()
      })
    },
  },
})
const exerciseCreateVariationModal = useModal({
  component: getAsyncComponent(
    'ManagerGroupCustomOptionExerciseDetailsTableExerciseFlowCreateVariation',
  ),
  attrs: {
    onConfirm: async ({ optionExercisedExecutionDate }) => {
      try {
        // Updating
        if (itemValue.value) {
          update(itemValue.value, {
            isExercised: !itemValue.value.isExercised,
            optionExercisedExecutionDate,
            createNewLease: false,
          })
          await submitForm()
          exerciseCreateVariationModal.close().then(() => {
            itemValue.value = undefined
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    onClose: () => {
      exerciseCreateVariationModal.close().then(() => {
        exerciseModal.open()
      })
    },
  },
})

const cancelExercise = useConfirmHandler(
  // We can safely assume that variationId is defined here because of the `beforeSubmit` option
  async () => {
    itemValue.value!.isExercised = false
    await submitForm()
  },
  {
    modal: {
      title: 'Cancel exercise',
      body: 'Are you sure you want to cancel this option exercise?',
      confirmInputText: 'CANCEL',
      confirmClass:
        'bg-primary focus:bg-primary hover:bg-primary-700 text-black hover:ring ring-primary ring-opacity-30',
    },
    loadingMessage: false,
    successMessage: false,
    errorMessage: 'Failed to cancel exercise',
    submitHandlerOptions: {
      beforeSubmit: () => {
        if (!itemValue.value) return false
      },
      onFinally: () => {
        itemValue.value = undefined
      },
    },
  },
)

const onExercise = ({
  __id,
}: IOptionExerciseDetailsValue & { __id: string }) => {
  const item = getById(__id)
  if (!item) return
  itemValue.value = item

  if (item.isExercised) {
    cancelExercise(item)
  } else {
    exerciseModal.open()
  }
}
const onIncludeInForecast = (id: string, value?: boolean) => {
  updateById(id, { includeInForecast: !value })
}
</script>

<template>
  <div :class="[colSize, 'grid grid-flow-row grid-cols-1 gap-2']">
    <OptionExerciseDetailsTable
      :label="node.label ?? undefined"
      :data="filteredItems"
      :lease-date-format="leaseDateFormat"
      :empty-message="node.emptyMessage"
      :original-expiry-date="leaseEndDate"
      :current-expiry-date="leaseEndDate"
      can-exercise
      can-include-in-forecast
      utc
      @edit="({ __id }) => onEdit(__id)"
      @exercise="(item) => onExercise(item)"
      @include-in-forecast="
        ({ __id, includeInForecast }) =>
          onIncludeInForecast(__id, includeInForecast)
      "
    />
  </div>
</template>
